<template>
  <van-dialog
    v-model="show"
    title="預約時間"
    width="900px"
    :show-confirm-button="false"
    @close="handleClose"
    close-on-click-overlay
    style="top:49%"
  >
    <div class="container">
      <div class="weekList">
        <div
          :class="item.isSelect == true ? 'week_item_1' : 'week_item_2'"
          v-for="(item, index) in weekOptions"
          :key="index"
          @click="handleSelectDate(item)"
        >
          <div>{{ item.week }}</div>
          <div class="select" v-if="item.isSelect">
            <img
              src="@/assets/image/adultTest/select-icon.png"
              alt=""
            />
          </div>
          <div>{{ item.date }}</div>
        </div>
        <!-- <div class="week_item_2" v-for="item in 6">
          <div>星期一</div>
          <div>1月10日</div>
        </div> -->
      </div>
      <div v-if="timeCount > 0">
        <h3>上午</h3>
        <div class="morning">
          <div v-for="(item, index) in TimePeriods" :key="index">
            <div
              v-if="item.timeFrame == 'AM'"
              :class="
                item.isOpt
                  ? 'surplus_item_1'
                  : item.isValid === 0 && item.leftNum > 0
                  ? 'surplus_item_2'
                  : 'surplus_item_3'
              "
              @click="item.isValid !== 1 && item.leftNum > 0?handleItem(item):null"
            >
              <div>{{ item.testStartTime }}-{{ item.testEndTime }}</div>
              <div class="select" v-if="item.isOpt">
                <img
                  src="@/assets/image/adultTest/select-icon.png"
                  alt=""
                />
              </div>
              <div>剩餘：{{ item.leftNum }}</div>
            </div>
          </div>

          <!-- <div class="surplus_item_2">
            <div>9:00-10:00</div>
            <div>剩餘：2</div>
          </div>
          <div class="surplus_item_3">
            <div>9:00-10:00</div>
            <div>剩餘：2</div>
          </div> -->
        </div>
        <h3>下午</h3>
        <div class="afternoon">
          <div v-for="(item, index) in TimePeriods" :key="index">
            <div
              v-if="item.timeFrame == 'PM'"
              :class="
                item.isOpt
                  ? 'surplus_item_1'
                  : item.isValid === 0 && item.leftNum > 0
                  ? 'surplus_item_2'
                  : 'surplus_item_3'
              "
              @click="item.isValid !== 1 && item.leftNum > 0?handleItem(item):null"
            >
              <div>{{ item.testStartTime }}-{{ item.testEndTime }}</div>
              <div class="select" v-if="item.isOpt">
                <img
                  src="@/assets/image/adultTest/select-icon.png"
                  alt=""
                />
              </div>
              <div>剩餘：{{ item.leftNum }}</div>
            </div>
          </div>
          <!-- <div class="surplus_item_1">
            <div>9:00-10:00</div>
            <div>剩餘：2</div>
          </div>
          <div class="surplus_item_2">
            <div>9:00-10:00</div>
            <div>剩餘：2</div>
          </div>
          <div class="surplus_item_3">
            <div>9:00-10:00</div>
            <div>剩餘：2</div>
          </div> --> 
        </div>
      </div>
      <div v-if="timeCount < 1" class="no_data">
        <img src="@/assets/image/adultTest/no-data.png" alt="" />
         <p>当前日期已约满，请选择其他日期</p>
      </div>
      <div class="confirm_botton" @click="handleSubmit">
        <span>確定</span>
      </div>
    </div>
    <div class="icon_close" @click="handleClose">
      <img src="@/assets/image/guanbi.png" alt="" />
    </div>
  </van-dialog>
</template>
<script>
import { Checkbox, Field, Toast, Dialog, Icon, Button } from 'vant'
import API from '@/api/index'
import moment from 'moment'
export default {
  name: 'AppointedTimeModal',
  components: {
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  // props: {
  //   selectTime: {
  //     type: Object,
  //     default() {
  //       return {}
  //     }
  //   }
  // },
  data() {
    return {
      show: true,
      weekOptions: [],
      TimePeriods: [],
      selectTime:undefined,
      timeCount:0,
    }
  },
  mounted() {
    this.weekOptions = []
    let time = new Date()
    for (let i = 0; i < 7; i++) {
      let obj = {}
      let day = moment(moment(time).add(i+2, 'd')).day()
      obj.date = moment(time).add(i+2, 'd').format('MM月DD日')
      obj.YTD = moment(time).add(i+2, 'd').format('YYYY-MM-DD')
      obj.week = this.getWeek(day)
      obj.isSelect = i !== 0 ? false : true
      this.weekOptions.push(obj)
    }
    this.handleSelectDate(this.weekOptions[0], 'int')

  },
  methods: {
    getWeek(day) {
      let weekName = '星期日'
      switch (parseInt(day)) {
        case 1:
          weekName = '星期一'
          break
        case 2:
          weekName = '星期二'
          break
        case 3:
          weekName = '星期三'
          break
        case 4:
          weekName = '星期四'
          break
        case 5:
          weekName = '星期五'
          break
        case 6:
          weekName = '星期六'
          break
      }
      return weekName
    },
    handleClose() {
      this.$emit('cancel')
    },
    handleSubmit() {
      // console.log(this.selectTime)
      if (!this.selectTime) {
        Toast('請選擇時間')
        return
      }
      this.$emit('selectData', this.selectTime)
      this.$emit('cancel')
    },
    handleSelectDate(item, type) {
			this.timeCount = 0;
      if (!type) {
        this.selectTime = undefined
      }
      this.weekOptions.map((i) => {
        if (item.week == i.week) {
          i.isSelect = true
          Toast.loading({
            forbidClick: true,
            overlay: true,
            duration: 0
          })

          API.getAppointments(i.YTD)
            .then((res) => {
              Toast.clear()
              if (res.code == 0) {
                this.TimePeriods = res.rows.map((item) => {
                  if(item.isValid === 0){
                    this.timeCount = this.timeCount + item.leftNum
                  }
                  let val = item.testStartTime.split(':')[0]
                  // console.log(Number(val) < 12, val)
                  item['isOpt'] = false
                  if (Number(val) < 12) {
                    item['timeFrame'] = 'AM'
                  } else {
                    item['timeFrame'] = 'PM'
                  }
                  return item
                })
				
				console.log(this.timeCount)
              } else {
                Toast(res.msg)
              }
            })
            .catch((error) => {
              Toast.fail('error')
              console.log(error)
            })
        } else {
          i.isSelect = false
        }
        return i
      })
    },
    handleItem(item) {
      if (item.is_valid > 0) {
        return
      }
      this.TimePeriods.map((i) => {
        if (item.testStartTime == i.testStartTime) {
          i.isOpt = true
          this.selectTime = i
        } else {
          i.isOpt = false
        }
        return i
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 730px;
  margin: 0 auto;
  .weekList {
    display: flex;
    margin-top: 32px;
    .week_item_1 {
      margin-right: 12px;
      width: 94px;
      height: 66px;
      background: #f0f3ff;
      border-radius: 8px;
      border: 1px solid #122ab0;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 14px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      div:first-child {
        width: 100%;
        margin: 12px 0 4px 0;
      }
      div:last-child {
        width: 100%;
        margin: 0px 0 16px 0;
        color: #666;
      }
      .select {
        width: 24px;
        height: 16px;
        background: #122ab0;
        border-radius: 8px 0 0 0;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .week_item_2 {
      margin-right: 12px;
      width: 94px;
      height: 66px;
      background: #fff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #333;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 14px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      div:first-child {
        width: 100%;
        margin: 12px 0 4px 0;
      }
      div:last-child {
        width: 100%;
        margin: 0px 0 16px 0;
        color: #666;
      }
    }
  }
  h3 {
    margin: 28px 0 2px 0;
    text-align: left;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 19px;
  }
  .morning {
    display: flex;
    flex-wrap: wrap;
  }
  .afternoon {
    display: flex;
    flex-wrap: wrap;
  }
  .surplus_item_1 {
    margin: 14px 14px 0 0;
    width: 94px;
    height: 66px;
    background: #f0f3ff;
    border-radius: 8px;
    border: 1px solid #122ab0;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 14px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    div:first-child {
      width: 100%;
      margin: 12px 0 4px 0;
    }
    div:last-child {
      width: 100%;
      margin: 0px 0 16px 0;
      color: #122ab0;
    }
    .select {
      width: 24px;
      height: 16px;
      background: #122ab0;
      border-radius: 8px 0 0 0;
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .surplus_item_2 {
    margin: 14px 14px 0 0;
    width: 94px;
    height: 66px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #262626;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 14px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    div:first-child {
      width: 100%;
      margin: 12px 0 4px 0;
    }
    div:last-child {
      width: 100%;
      margin: 0px 0 16px 0;
      color: #122ab0;
    }
  }
  .surplus_item_3 {
    margin: 14px 14px 0 0;
    width: 94px;
    height: 66px;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #999999;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    div:first-child {
      width: 100%;
      margin: 12px 0 4px 0;
    }
    div:last-child {
      width: 100%;
      margin: 0px 0 16px 0;
    }
  }
  .confirm_botton {
    margin: 48px auto 32px auto;
    cursor: pointer;
    width: 311px;
    height: 44px;
    background: #122ab0;
    border-radius: 46px;
    span {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 44px;
    }
  }
  .no_data {
    margin: 42px auto 0 auto;
    padding-bottom: 33px;
    img {
      width: 200px;
      height: 145px;
    }
    p {
      margin-top: 24px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
    }
  }
}
.icon_close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 20px;
  }
}
/deep/ .van-dialog__header {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 21px;
  padding-top: 32px;
}
</style>
