<template>
	<div class="activity_page" v-show="viewShow">
		<div class="headBackground">
			<div class="logo"><img src="@/assets/image/adultTest/logo.png" alt="" /></div>
			<div class="theme"><img src="@/assets/image/adultTest/text.png" alt="" /></div>
		</div>
		<div style="width: 680px; margin: 0 auto">
			<div class="navigation">
				<img :src="left_arrow" class="left_arrow"  @click="goHistory()" alt="" />
				<div class="titleText" @click="goHistory()">{{ getNavName() }}</div>
			</div>
		</div>
		<!-- 考生信息填写 -->
		<div class="fill_in" v-if="queryType == 'pre'">
			<div class="card_1">
				<div class="input-box"><van-field label="證件姓名" v-model="form.testName" placeholder="请输入證件姓名" input-align="right" /></div>
				<div class="input-box"><van-field label="證件英文姓名" v-model="form.testNamePinyin" placeholder="请输入證件英文姓名" input-align="right" /></div>
				<div class="input-box">
					<van-field label="手機號">
						<div slot="input" style="position: absolute; right: 0; display: flex">
							<CountryCodeSelector :searchbar="false" :countryCode.sync="areaCode" style="width: 74px; margin-right: 16px"></CountryCodeSelector>
							<van-field v-model="form.phone" placeholder="请输入手機號" type="number" class="cell" input-align="right" />
						</div>
					</van-field>
				</div>
				<div class="input-box">
					<van-field label="手機驗證碼" v-model="form.verifyCode" placeholder="請輸入驗證碼" type="number" class="fill" input-align="right">
						<template #button>
							<div class="code_button" @click="getVerificationCode">{{ codeText }}</div>
						</template>
					</van-field>
				</div>
				<div class="input-box"><van-field label="電郵" v-model="form.email" placeholder="请输入電郵" input-align="right" /></div>
				<div class="input-box">
					<van-field label="預約时间" input-align="right">
						<template #button>
							<div class="to_button" @click="toAppointmentTime">
								<div v-if="!testTime">立即預約</div>
								<div v-if="testTime">{{ testTime }}</div>
								<img :src="right_arrow" alt="" />
							</div>
						</template>
					</van-field>
				</div>
			</div>
			<div class="card_2">
				<div class="input-box">
					<van-field label="證件類型" input-align="right">
						<template #button>
							<div class="to_button" @click="showPopover = true">
								<van-popover v-model="showPopover" trigger="click" :actions="optionsList" @select="onCertificateSelect">
									<template #reference>
										<div :class="form.idType != null ? 'selectColor_2' : 'selectColor_1'">{{ documentName }}</div>
									</template>
								</van-popover>
								<img :src="right_arrow" alt="" />
							</div>
						</template>
					</van-field>
				</div>
				<div class="input-box"><van-field label="證件號" v-model="form.idNo" placeholder="请输入證件號" input-align="right" /></div>
			</div>
		</div>
		<!-- 预约查询 -->
		<div class="result" v-if="queryType == 'showList'">
			<div class="result_card" v-for="(item, index) in renderData" :key="index">
				<div class="card_left">
					<div>{{ item.testName }}</div>
					<div>{{ item.testDate.split(' ')[0] }}</div>
				</div>
				<div class="card_right" @click="handleResultItem(item)">
					<div v-if="item.testStatus === 0">進行中</div>
					<div v-if="item.testStatus === 1" style="color: #333">待評分</div>
					<div v-if="item.testStatus === 2" style="color: #999999">已取消</div>
					<div v-if="item.testStatus === 3" style="color: #333">已完成</div>
					<div v-if="item.testStatus === 4" style="color: #999">已失效</div>
					<img :src="right_arrow" alt="" />
				</div>
			</div>
		</div>
		<!-- 预约详情 -->
		<div class="details" v-if="queryType == 'wait_exam' || queryType == 'end' || queryType == 'wait_score' || queryType == 'shixiao' || isCancel">
			<div class="status_module">
				<p>{{ queryType == 'wait_exam' ? '等待考試' : queryType == 'wait_score' ? '等待評分' : queryType == 'end' ? '已完成考試': queryType == 'shixiao' ? '已失效' : isCancel ? '已取消' : null }}</p>
				<div v-if="isCancel" class="cancelTime">取消時間：{{ resultInfo.cancelTime }}</div>
				<div v-if="!isCancel && queryType != 'shixiao'" class="test_number">
					<span class="text">你的考生號為：</span>
					<span class="number">{{ resultInfo.testCode }}</span>
					<div class="copy_button" @click="copyNumber">複製</div>
				</div>
			</div>

			<div class="card_1">
				<div class="row-box">
					<div>證件姓名</div>
					<div>{{ resultInfo.testName }}</div>
				</div>
				<div class="row-box">
					<div>證件英文姓名</div>
					<div>{{ resultInfo.testNamePinyin }}</div>
				</div>
				<div class="row-box">
					<div>手機號</div>
					<div>+{{ resultInfo.areaCode }}-{{ resultInfo.phone }}</div>
				</div>
				<div class="row-box">
					<div>電郵</div>
					<div>{{ resultInfo.email }}</div>
				</div>
				<div class="row-box">
					<div>預約时间</div>
					<div>{{ resultInfo.testDate.split(' ')[0] }} {{ resultInfo.testStartTime }}</div>
				</div>
			</div>
			<div class="card_2">
				<div class="row-box">
					<div>證件類型</div>
					<div>{{ resultInfo.idType === 0 ? '身份证' : resultInfo.idType == 1 ? '护照' : null }}</div>
				</div>
				<div class="row-box">
					<div>證件號</div>
					<div>{{ resultInfo.idNo }}</div>
				</div>
			</div>
		</div>
		<!-- 取消预约 -->
		<div class="cancel" v-if="queryType == 'cancel' && !isCancel">
			<div class="cause">請輸入取消原因</div>
			<hr class="hr" />
			<div class="input-box">
				<van-field v-model="cancelReason" rows="4" autosize type="textarea" maxlength="100" placeholder="輸入您取消的原因" show-word-limit style="background: #fafafa" />
			</div>
			<!-- <div class="CancelDescription"><p>1. 每人只能取消1次；</p></div> -->
		</div>
		<!-- 成绩单 -->
		<div class="report" v-if="!isView && queryType == 'result'">
			<div class="ground_floor"></div>
			<div class="report_card">
				<div class="core">
					<div class="grade">{{ renderData.level }}</div>
					<div class="name">{{ renderData.testName }}</div>
					<div class="namePinyin">{{ renderData.testNamePinyin }}</div>
					<div class="card_info">
						<div class="row-box">
							<div>分數</div>
							<div>{{ renderData.total }}</div>
						</div>
						<div class="row-box">
							<div>等级</div>
							<div>{{ renderData.level }}</div>
						</div>
						<div class="row-box">
							<div>考生號</div>
							<div>{{ renderData.testCode }}</div>
						</div>
						<div class="row-box" v-if="renderData.level != '不入等級'">
							<div>證書編號</div>
							<div>{{ renderData.certificateNo }}</div>
						</div>
						<div class="row-box"  v-if="renderData.level != '不入等級'">
							<div>發證日期</div>
							<div>{{ renderData.certificateDate }}</div>
						</div>
						<div class="row-box" v-if="false">
							<div>證書一覽</div>
							<!--  -->
							<div class="view_certificate" @click="isView = true">
								<!-- <a :href="renderData.certificateUrl" style="display: flex;align-items: center;"> -->
								<div style="color: #122ab0">點擊查看</div>
								<img :src="right_arrow" alt="" />
								<!-- </a> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isView && queryType == 'result'" style="margin: auto;margin-bottom: 50px;width: 680px;">
			<img :src="renderData.imageUrl" alt="" v-if="renderData.imageUrl != null" style="width: 100%;"/> 
			<pdfImg :url="renderData.pdfUrl" v-else/>
		</div>
		<div v-if="isView && queryType == 'cert'" style="margin: auto;margin-bottom: 50px;width: 680px;">
			<img :src="renderData.imageUrl" alt="" v-if="renderData.imageUrl != null" style="width: 100%;"/> 
			<pdfImg :url="renderData.pdfUrl" v-else/>

			<div class="downloadPdf" @click="pdfBut"> <span>證書下載</span></div>
		</div>
		<div v-if="queryType == 'pre'" class="button-box" @click="submitForm"><span native-type="submit">提交信息</span></div>
		<div v-if="queryType == 'wait_exam'" class="button-box" @click="queryType = 'cancel'"><span>取消預約</span></div>
		<div v-if="queryType == 'cancel' && !isCancel" class="button-box" @click="handleCancel"><span>確定取消</span></div>
		<AppointedTimeModal v-if="showModal" @cancel="showModal = false" @selectData="handelSelectData"></AppointedTimeModal>
	</div>
</template>

<script>
import { Checkbox, Field, Toast, Dialog, Icon, Button, Popover } from 'vant';
import CountryCodeSelector from '@/components/CountryCodeSelector';
import AppointedTimeModal from '@/components/pc/AppointedTimeModal.vue';
import pdfImg from '@/components/pdfImg';
import axios from 'axios';
import API from '@/api/index';
import download from '@/utils/download';
import { mapState } from 'vuex';  
export default {
	name: 'InformationFilling',
	components: {
		CountryCodeSelector,
		AppointedTimeModal,
		[Dialog.name]: Dialog,
		[Checkbox.name]: Checkbox,
		[Field.name]: Field,
		[Toast.name]: Toast,
		[Icon.name]: Icon,
		[Popover.name]: Popover,
		[Button.name]: Button,
		pdfImg
	},
	data() {
		return {
			areaCode: 852,
			codeText: '點擊發送',
			isGetCode: false, //发送状态
			showModal: false, // 弹框
			isView: false, // 证书预览
			form: {
				verifyCode: null, // 验证码
				phone: null, // 手機號
				testName: null, // 姓名
				testNamePinyin: null, // 英文名
				email: null, // 電郵
				idType: null, // 證件類型
				idNo: null ,// 證件號码  
			},
			left_arrow: require('@/assets/image/adultTest/left_arrow.png'),
			right_arrow: require('@/assets/image/adultTest/right_arrow.png'),
			cancelReason: '', // 取消预约原因
			queryType: '',
			isCancel: false, // 预约取消状态
			toHistory: '', // 返回
			optionsList: [{ text: '身份證', value: 0 }, { text: '護照', value: 1 }],
			showPopover: false,
			documentName: '請選擇',
			renderData: undefined, // 渲染的数据
			testTime: undefined, // 测试时间
			selectTime: undefined, // 选中的时间
			resultInfo: undefined ,// 预约详情  
		};
	},
	computed: {
	  ...mapState(['isPc','viewShow'])
	},
	mounted() {
		this.queryType = this.$route.params.type;
		this.renderData = this.$route.params.data;
		console.log(this.renderData);
		if (!this.queryType) {
			this.$router.replace({
						name: 'testLogIn',
						query: { type: 'pre' }
					});
		}

		if (this.queryType == 'cert') {
			this.isView = true; 
		}
	},
  created() {
    const resizeEvt =
      'orientationchange' in window ? 'orientationchange' : 'resize'
    const resetRecalc = () => {
      const __clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth
      if (__clientWidth < 1024) {
				// 手机端
        this.$router.replace({ name: 'reservationForm' })
      }
    }
    window.addEventListener(resizeEvt, resetRecalc.bind(this), false)
    window.addEventListener('load', resetRecalc.bind(this), false)
  },
	methods: {
		pdfBut() {
			download(this.renderData.pdfUrl)
		},
		// 获取验证码
		getVerificationCode() {
			if (this.isGetCode) return false;
			let regPhone = this.areaCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/;
			const { phone } = this.form;

			if (!phone) {
				Toast('请输入手機號码');
				return false;
			}

			if (!regPhone.test(phone)) {
				Toast('手機號码有误，请重新输入');
				return false;
			}

			const phoneList = [];
			phoneList.push('+' + this.areaCode + '-' + phone);
			const params = {
				appType: 12,
				verifyType: 9,
				phoneList,
				smsTemplateType: 10,
				signType: 1
			};
			API.sendVerificationCode(params)
				.then(res => {
					console.log(res);
					if (res.code == 0) {
						//请求成功
						Toast.success('驗證碼發送成功');
						this.countFun(59);
					} else {
						Toast.fail(res.msg);
					}
				})
				.catch(error => {
					//请求失败
					Toast.fail('error');
					console.log(error);
				});
		},
		countFun(time) {
			this.isGetCode = true;
			this.codeText = `${time}s`;
			let timer = setInterval(() => {
				if (time > 1) {
					time--;
					this.codeText = time >= 10 ? `${time}s` : `0${time}s`;
				} else {
					clearInterval(timer);
					this.codeText = '點擊發送';
					this.isGetCode = false;
					return true;
				}
			}, 1000);
		},
		// 复制考生号
		copyNumber() {
			const cInput = document.createElement('input');
			cInput.value = this.resultInfo.testCode;
			document.body.appendChild(cInput);
			cInput.select(); // 选取文本域内容;
			document.execCommand('Copy');
			Toast.success('考生号复制成功');
			/// 复制成功后再将构造的标签 移除
			cInput.remove();
		},
		// 提交
		submitForm() {
			const { testName, testNamePinyin, phone, verifyCode, email, idType, idNo } = this.form;
			let regPhone = this.areaCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/;
			let regEmail = /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/;
			if (!testName || !testName.trim()) {
				Toast('请输入證件姓名');
				return false;
			} else if (!testNamePinyin || !testNamePinyin.trim()) {
				Toast('请输入證件英文姓名');
				return false;
			} else if (!phone) {
				Toast('请输入手機號码');
				return false;
			} else if (!regPhone.test(phone)) {
				Toast('手機號碼有誤，請重新輸入');
				return false;
			} else if (!verifyCode) {
				Toast('驗證碼不能為空');
				return false;
			} else if (!email) {
				Toast('请输入電郵');
				return false;
			} else if (!regEmail.test(email.trim())) {
				Toast('電郵格式有误，請重新輸入');
				return false;
			} else if (idType === null) {
				Toast('請選擇證件類型');
				return false;
			} else if (!idNo) {
				Toast('請輸入證件號碼');
				return false;
			} else if (!this.selectTime) {
				Toast('請選擇預約时间');
				return false;
			}
			console.log(this.areaCode, this.form);
			const { testDate, testStartTime, testEndTime } = this.selectTime;
			const params = {
				testName: testName.trim(),
				testNamePinyin: testNamePinyin.trim(),
				phone,
				verifyCode,
				email: email.trim(),
				idType,
				idNo,
				areaCode: this.areaCode,
				testDate,
				testStartTime,
				testEndTime
			};
			Toast.loading({
				message: '提交中...',
				forbidClick: true,
				overlay: true,
				duration: 0
			});
			API.saveAppointments(params)
				.then(res => {
					console.log(res, 'res');
					Toast.clear();
					if (res.code == 0) {
						this.$router.replace({
							name: 'successState',
							params: { data: res.data }
						});
					} else {
						Toast(res.msg);
					}
				})
				.catch(error => {
					Toast.clear();
					Toast.fail('error');
					console.log(error);
				});
		},
		toAppointmentTime() {
			this.showModal = true;
		},
		getNavName() {
			let history = null;
			switch (true) {
				case this.queryType == 'pre':
					history = '考生信息填寫';
					this.toHistory = null;
					break;
				case this.queryType == 'showList':
					history = '預約查詢';
					this.toHistory = 'query_pre';
					break;
				case this.queryType == 'result' && this.isView == false:
					history = '成績單';
					this.toHistory = 'query_result';
					break;
				case this.isView == true && this.queryType == 'result':
					history = '證書查詢';
					this.toHistory = 'result';
					break;
				case this.isView == true && this.queryType == 'cert':
					history = '證書查詢';
					this.toHistory = 'cert';
					break;
				case ['wait_exam', 'wait_score','shixiao', 'end', 'cancel'].includes(this.queryType) || this.isCancel == true:
					history = '預約詳情';
					this.toHistory = 'showList';
					break;
				default:
					history = null;
					this.toHistory = null;
					break;
			}
			return history;
		},
		goHistory() {
			switch (true) {
				case this.toHistory == null:
					this.$router.go(-1);
					break;
				case this.toHistory == 'query_pre':
					this.$router.replace({
						name: 'testLogIn',
						query: { type: 'pre' }
					});
					this.$router.go(-1);
					break;
				case this.toHistory == 'query_result':
					this.$router.replace({
						name: 'testLogIn',
						query: { type: 'result' }
					});
					this.$router.go(-1);
					break;
				case this.toHistory == 'result':
					this.queryType = 'result';
					this.isView = false;
					break;
				case this.toHistory == 'showList':
					this.queryType = 'showList';
					this.isCancel = false;
					break;
				default:
					this.$router.go(-1);
					break;
			}
		},
		onCertificateSelect(action) {
			this.documentName = action.text;
			this.form.idType = action.value;
		},
		handelSelectData(data) {
			this.selectTime = data;
			const { testDate, testStartTime, testEndTime } = data;
			this.testTime = testDate.split(' ')[0] + ' ' + testStartTime + '-' + testEndTime;
		},
		handleResultItem(item) {
			switch (item.testStatus) {
				case 0:
					this.queryType = 'wait_exam';
					break;
				case 1:
					this.queryType = 'wait_score';
					break;
				case 2:
					this.queryType = '';
					this.isCancel = true;
					break;
				case 3:
					this.queryType = 'end';
					break;
				case 4:
					this.queryType = 'shixiao';
					break;
				default:
					this.queryType = '';
					break;
			}
			this.resultInfo = item;
		},
		handleCancel() {
			if (!this.cancelReason || !this.cancelReason.trim()) {
				Toast('請輸入取消原因');
				return;
			}
			const params = {
				cancelReason: this.cancelReason,
				testCenterId: this.resultInfo.testCenterId
			};
			Toast.loading({
				message: '提交中...',
				forbidClick: true,
				overlay: true,
				duration: 0
			});
			API.cancelAppointments(params)
				.then(res => {
					Toast.clear();
					if (res.code == 0) {
            Toast.success('取消成功');
            this.queryType = 'showList'
            if(window.sessionStorage.getItem('mimi')){
            this.updateList()
            }else{  
              Toast('檢查您未登録')
              // pre:預約查詢  result:成績查詢
              this.$router.push({ name: 'testLogIn', query: { type:'pre' } })
            }
					} else {
						Toast(res.msg);
					}
				})
				.catch(error => {
					Toast.clear();
					Toast.fail('error');
					console.log(error);
				});
		},
		updateList(){
			let a = JSON.parse(decodeURI(window.sessionStorage.getItem('mimi'))); 
			Toast.loading({
				forbidClick: true,
				overlay: true,
				duration: 0
			})
			let data={
				prefix:a.prefix,
				name:a.name,
				phone:a.phone
			}
			API.getRefresh(data)
			.then((res) => {
				Toast.clear()
				if (res.code == 0) {  
				 this.renderData = res.rows
				} else {
				  Toast(res.msg)
				}
			})
			.catch((err) => {
				Toast.clear()
			})
		},
	}
};
</script>

<style lang="scss" scoped>
html,
body {
	min-width: 980px;
}


.downloadPdf{
    margin: 48px auto 32px auto;
    cursor: pointer;
    width: 311px;
    height: 44px;
    background: #122ab0;
    border-radius: 46px;
	
    span {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 44px;
    }
  }

.activity_page {
	width: 100vw;
	min-height: 100vh;
	overflow: hidden;
	display: table;
	background: #f1f4fe;
	.headBackground {
		height: 296px;
		background: url('~@/assets/image/adultTest/headBackground.jpg') no-repeat;
		background-size: 100% 296px;
		background-position: center center;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo {
			background: #ffffff;
			box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
			border-radius: 16px;
			padding: 10px 16px;
			box-sizing: border-box;
			width: 80px;
			height: 80px;
			margin-right: 24px;
			img {
				width: 48px;
				height: 60px;
			}
		}
		.theme {
			img {
				width: 571px;
			}
		}
	}
	.navigation {
		margin: 32px auto 46px auto;
		display: flex;
		align-items: center;
		.left_arrow {
			width: 24px;
			height: 24px;
			padding-right: 16px;
			cursor: pointer;
		}
		.titleText {
			font-size: 16px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			line-height: 19px;
			cursor: pointer;
		}
	}
	.fill_in {
		.card_1 {
			width: 680px;
			height: 408px;
			padding: 0 28px;
			margin: 0 auto 24px auto;
			background: #ffffff;
			border-radius: 8px;
			box-sizing: border-box;
			.input-box {
				height: 67px;
				border-bottom: 1px solid #f2f2f2;
				display: flex;
				align-items: center;
				position: relative;
				.cell {
					padding: 0;
					width: 148px;
					border-left: 1px solid #f2f2f2;
				}
				.code_button {
					margin-left: 24px;
					font-size: 14px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #122ab0;
					line-height: 16px;
					cursor: pointer;
				}
				.to_button {
					font-size: 14px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #122ab0;
					line-height: 16px;
					cursor: pointer;
					display: flex;
					align-items: center;
					img {
						margin-left: 8px;
						height: 12px;
						width: 12px;
					}
				}
			}
			.input-box:last-child {
				border-bottom: none;
			}
		}
		.card_2 {
			width: 680px;
			height: 136px;
			padding: 0 28px;
			margin: 0 auto 59px auto;
			background: #ffffff;
			border-radius: 8px;
			box-sizing: border-box;
			.input-box {
				height: 67px;
				border-bottom: 1px solid #f2f2f2;
				display: flex;
				align-items: center;
				position: relative;
			}
			.input-box:last-child {
				border-bottom: none;
			}
			.to_button {
				font-size: 14px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #122ab0;
				line-height: 16px;
				cursor: pointer;
				display: flex;
				align-items: center;
				img {
					margin-left: 8px;
					height: 12px;
					width: 12px;
				}
			}
		}
	}
	.result {
		margin: 50px auto 0 auto;
		.result_card {
			margin: 0 auto 24px auto;
			width: 680px;
			height: 105px;
			background: #ffffff;
			box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
			border-radius: 8px;
			font-family: PingFang SC-Semibold, PingFang SC;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.card_left {
				margin-left: 28px;
				text-align: left;
				div:first-child {
					margin-bottom: 12px;
					font-size: 20px;
					font-weight: 600;
					color: #333333;
					line-height: 23px;
				}
				div:nth-child(2) {
					font-size: 12px;
					font-weight: 400;
					color: #999999;
					line-height: 14px;
				}
			}
			.card_right {
				font-size: 16px;
				font-weight: 400;
				color: #122ab0;
				display: flex;
				align-items: center;
				margin-right: 28px;
				cursor: pointer;
				div:first-child {
					margin-right: 3px;
					line-height: 24px;
				}
			}
		}
	}
	.details {
		.status_module {
			font-family: PingFang SC-Semibold, PingFang SC;
			p {
				margin: 0px 0 16px 0;
				font-size: 18px;
				font-weight: 600;
				color: #333333;
				line-height: 19px;
			}
			.cancelTime {
				font-size: 14px;
				color: #333333;
			}
			.test_number {
				font-size: 14px;
				font-weight: 400;
				color: #999999;
				line-height: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				.number {
					color: #333333;
				}
				.copy_button {
					margin-left: 8px;
					width: 48px;
					height: 20px;
					background: #122ab0;
					border-radius: 46px;
					font-size: 12px;
					font-weight: 400;
					color: #ffffff;
					line-height: 20px;
					cursor: pointer;
				}
			}
		}
		.card_1 {
			width: 680px;
			height: 340px;
			padding: 0 28px;
			margin: 28px auto 0 auto;
			background: #ffffff;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 14px;
			color: #333333;
			font-family: PingFang SC-Regular, PingFang SC;
			.row-box {
				height: 67px;
				border-bottom: 1px solid #f2f2f2;
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 68px;
			}
			.row-box:last-child {
				border-bottom: none;
			}
		}
		.card_2 {
			width: 680px;
			height: 136px;
			padding: 0 28px;
			margin: 28px auto 48px auto;
			background: #ffffff;
			border-radius: 8px;
			overflow: hidden;
			box-sizing: border-box;
			font-size: 14px;
			color: #333333;
			font-family: PingFang SC-Regular, PingFang SC;
			.row-box {
				height: 67px;
				border-bottom: 1px solid #f2f2f2;
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 68px;
			}
			.row-box:last-child {
				border-bottom: none;
			}
		}
	}
	.cancel {
		margin: 0 auto 48px auto;
		padding: 24px 28px;
		width: 680px;
		background: #ffffff;
		box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		font-family: PingFang SC-Semibold, PingFang SC;
		box-sizing: border-box;
		.cause {
			font-size: 18px;
			font-weight: 600;
			color: #333333;
			line-height: 29px;
			text-align: left;
		}
		.hr {
			margin: 16px 0;
			background-color: #f2f2f2;
			height: 1px;
			border: none;
		}
		.input-box {
			background: #fafafa;
			border-radius: 2px;
			overflow: hidden;
		}
		.CancelDescription {
			text-align: left;
			margin-top: 16px;
			font-size: 14px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 28px;
		}
	}
	.report {
		position: relative;
		margin: 0 auto 50px auto;
		.ground_floor {
			margin: 0 auto;
			width: 648px;
			height: 578px;
			background: #ffffff;
			box-shadow: -1px 4px 20px 0px rgba(32, 71, 208, 0.15);
			border-radius: 4px;
		}
		.report_card {
			padding: 8px;
			width: 680px;
			height: 570px;
			background: #ffffff;
			box-shadow: -1px 4px 20px 0px rgba(32, 71, 208, 0.15);
			border-radius: 4px;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -340px;
			.core {
				width: 100%;
				height: 100%;
				border: 2px solid #2147cf;
				border-radius: 2px;
				box-sizing: border-box;
				font-family: PingFang SC-Semibold, PingFang SC;
				.grade {
					margin: 40px auto 8px auto;
					font-size: 24px;
					font-weight: 600;
					color: #e80202;
					line-height: 28px;
				}
				.name {
					font-size: 16px;
					font-weight: 600;
					color: #333333;
					line-height: 19px;
				}
				.namePinyin {
					margin-top: 4px;
					font-size: 12px;
					font-weight: 400;
					color: #999999;
					line-height: 17px;
				}
				.card_info {
					// border: 1px solid red;
					width: 360px;
					margin: -3px auto 0 auto;
					background: #ffffff;
					box-sizing: border-box;
					font-size: 14px;
					.row-box {
						height: 68px;
						border-bottom: 1px solid #f2f2f2;
						display: flex;
						justify-content: space-between;
						align-items: center;
						line-height: 62px;
						box-sizing: border-box;
						color: #333333;
						div:first-child {
							color: #999999;
						}
					}
					.row-box:last-child {
						border: none;
					}
					.view_certificate {
						display: flex;
						align-items: center;
						cursor: pointer;
						div:first-child {
							margin-right: 3px;
							line-height: 14px;
						}
						img {
							width: 12px;
						}
					}
				}
			}
		}
	}
	.button-box {
		margin: 0 auto 170px auto;
		width: 343px;
		height: 44px;
		background: #122ab0;
		border-radius: 46px;
		cursor: pointer;
		span {
			font-size: 16px;
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #ffffff;
			line-height: 44px;
		}
	}
}
.selectColor_1 {
	font-size: 14px;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #122ab0;
	cursor: pointer;
}
.selectColor_2 {
	color: #323233;
	font-size: 14px;
	cursor: pointer;
}
/deep/ .van-field__control {
	font-size: 14px;
}
/deep/ .van-cell {
	overflow: visible;
}
/deep/ .CountryCodeSelectorContainer {
	width: 150px;
	.arrow-down {
		width: 12px;
		height: 12px;
	}
}
</style>
